import React from "react"
import YoutubeBackground from "../YoutubeVideoBackground"
import styled from "styled-components"
import getYouTubeId from "get-youtube-id"
import breakpoints from "../../styles/breakpoints"

const ContentContainer = styled.div`
  width: 100%;
  background-position: center center;
  background-repeat: repeat-y;
  background-size: cover;
  height: ${(props) => (props.xl ? "95vh" : "70vh")};
  ${breakpoints.md`
    height: ${(props) => (props.xl ? "90vh" : "70vh")};
  `}
  display: flex;
  justify-content: center;
  align-items: center;
`
const HeroFullWidthWithYoutube = ({ children, youtubeUrl, xl }) => {
  const id = getYouTubeId(youtubeUrl)

  const opts = {
    end: 25,
  }
  return (
    <>
      <YoutubeBackground
        videoId={id}
        playerOptions={opts}
        overlay="rgba(0,0,0,0.7)"
      >
        <ContentContainer className="lga-bottom-border-white" xl={xl}>
          {children}
        </ContentContainer>
      </YoutubeBackground>
    </>
  )
}

export default HeroFullWidthWithYoutube
