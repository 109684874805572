import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import breakpoints from "../../styles/breakpoints"
import YoutubeVideo from "../YoutubeVideo"
import localeData from "../../locale"
import PrimaryBtn from "../Buttons/PrimaryBtn"
import ReviewAverageScore from "../ReviewAverageScore"
import Vspacer from "../Vspacer"
import vSpacing from "../../styles/vSpacing"

const Title = styled.h1`
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  width: 90%;
  ${breakpoints.md`
    width: 60%;
  `};
`

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  button {
    &:first-child {
      margin-bottom: 16px;
    }
  }
  ${breakpoints.md`
    flex-direction: row;
    button {
      &:first-child {
        margin-bottom: 0;
        margin-right: 24px;
      }
    }
  `};
`

const XLHeader = styled.div`
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  width: 90%;
  ${breakpoints.md`
    width: 60%;
  `};
  h1 {
    color: ${(props) => props.theme.colors.white};
    margin-bottom: 48px;
    text-align: center;
    font-size: 32px;
    ${breakpoints.md`
      font-size: 3.2rem;
    `};
  }
`

const ReviewSticker = styled.div`
  position: absolute;
`

const HomePageHeroContent = ({
  title,
  buttonLink1,
  youtubeUrl,
  locale,
  xl,
}) => {
  return (
    <>
      {!xl && <Title>{title}</Title>}
      {xl && (
        <XLHeader>
          <h1>{title}</h1>
          <BtnContainer>
            <Link to={buttonLink1}>
              <PrimaryBtn>
                {localeData._rawExploreVacancyBtn[locale]}
              </PrimaryBtn>
            </Link>
            {youtubeUrl && (
              <div>
                <YoutubeVideo
                  url={youtubeUrl}
                  btnText={localeData.YOUTUBE_ADVENTURE[locale]}
                />
              </div>
            )}
          </BtnContainer>
          <Vspacer paddingTop={vSpacing.baseX6} marginBottom={vSpacing.baseX6}>
            <ReviewAverageScore locale={locale} isHero />
          </Vspacer>
        </XLHeader>
      )}
    </>
  )
}

export default HomePageHeroContent
