import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import SEO from "../components/seo"
import Section from "../components/Section"
import HeroFullWidth from "../components/HeroFullWidth"
import HeroFullWidthWithYoutube from "../components/HeroFullWidthWithYoutube"
import HomePageHeroContent from "../components/HomePageHeroContent"
import SvgBorderTop from "../components/SvgBorderTop"
import { Container } from "../components/Container"
import breakpoints from "../styles/breakpoints"
import VacancySelection from "../components/VacancySelection"
import vSpacing from "../styles/vSpacing"
import Vspacer from "../components/Vspacer"
import LetsConnect from "../components/LetsConnect"
import BlockText from "../components/BlockText"
import HeroFullWidthMiddle from "../components/HeroFullWidthMiddle"
import IntroBlockStyle from "../components/IntroBlockStyle"
import slugify from "../utils/slugify"
import { LinkUnderline } from "../components/NormalLink"
import StorySelection from "../components/StorySelection"
import { AfricaOutlineBackground, Location } from "../styles/sharedStyles"
import localeData from "../locale"
import { currentLocale } from "../locale/languages"
import ShowMobileOnly from "../components/ShowMobileOnly"
import ShowDesktopOnly from "../components/ShowDesktopOnly"

const BlockTitle = styled.h2`
  color: ${(props) => props.theme.primaryColors.primary};
  margin-bottom: 24px;
`
const Title4 = styled.h2`
  text-align: center;
  color: ${(props) => props.theme.primaryColors.primary};
`

const Stats = styled.h1`
  text-align: center;
  margin-bottom: 24px;
  color: ${(props) => props.theme.primaryColors.primary};
`

const StatsTitle = styled.h5`
  font-size: 20px;
  color: ${(props) => props.theme.primaryColors.primaryBlack};
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;

  ${breakpoints.md`
    margin-bottom: 0;
  `};
`

export const query = graphql`
  {
    sanityHomePage {
      id
      _rawLocaleTitle
      _rawHeroTitle
      _rawTextBlock1
      youtubeBackgroundVideo
      image {
        asset {
          gatsbyImageData(width: 1600, fit: FILLMAX, placeholder: BLURRED)
        }
      }
      image2 {
        asset {
          gatsbyImageData(width: 1600, fit: FILLMAX, placeholder: BLURRED)
        }
      }
      teamMember {
        name
        degree
        phone
        email
        image {
          asset {
            url
          }
        }
      }
      _rawDestinationsTitle
      _rawDestinationstextBlock
      _rawProjectTypesTitle
      _rawProjectTypestextBlock
    }
    sanityAllVacanciesPage {
      id
      _rawLocaleTitle
    }
    sanityAboutUsPage {
      id
      _rawLocaleTitle
    }
    allSanityVacancyCountry {
      edges {
        node {
          id
          _rawLocaleTitle
          flag {
            asset {
              url
            }
          }
        }
      }
    }
    allSanityVacancyCategory {
      edges {
        node {
          id
          _rawLocaleTitle
          categoryIcon {
            asset {
              url
            }
          }
        }
      }
    }
    allSanityVacancy {
      edges {
        node {
          id
        }
      }
    }
    sanitySiteSettings {
      id
      amountOfPartners
      clientAmount
    }
  }
`

const IndexPage = ({ data, pageContext }) => {
  const { locale } = pageContext
  const {
    youtubeBackgroundVideo,
    image,
    _rawLocaleTitle,
    _rawHeroTitle,
    _rawTextBlock1,
    image2,
    _rawDestinationsTitle,
    _rawDestinationstextBlock,
    _rawProjectTypesTitle,
    _rawProjectTypestextBlock,
    teamMember,
  } = data.sanityHomePage

  const { sanityAllVacanciesPage, sanityAboutUsPage } = data
  const { allSanityVacancyCountry } = data
  const { allSanityVacancyCategory } = data
  const { allSanityVacancy } = data
  const { sanitySiteSettings } = data
  const setLocaleInUrl = currentLocale !== locale ? `${locale}/` : ""

  return (
    <>
      <SEO title={_rawLocaleTitle[locale]} />
      {youtubeBackgroundVideo && image ? (
        <>
          <ShowDesktopOnly>
            <HeroFullWidthWithYoutube youtubeUrl={youtubeBackgroundVideo} xl>
              <HomePageHeroContent
                title={_rawHeroTitle[locale]}
                locale={locale}
                buttonLink1={`/${setLocaleInUrl}${slugify(
                  sanityAllVacanciesPage._rawLocaleTitle[locale]
                )}`}
                youtubeUrl={youtubeBackgroundVideo}
                xl
              />
            </HeroFullWidthWithYoutube>
          </ShowDesktopOnly>
          <ShowMobileOnly>
            <HeroFullWidth img={image} title={_rawHeroTitle[locale]} xl>
              <HomePageHeroContent
                title={_rawHeroTitle[locale]}
                locale={locale}
                buttonLink1={`/${setLocaleInUrl}${slugify(
                  sanityAllVacanciesPage._rawLocaleTitle[locale]
                )}`}
                youtubeUrl={youtubeBackgroundVideo}
                xl
              />
            </HeroFullWidth>
          </ShowMobileOnly>
        </>
      ) : (
        image && (
          <HeroFullWidth img={image} title={_rawHeroTitle[locale]} xl>
            <HomePageHeroContent
              title={_rawHeroTitle[locale]}
              locale={locale}
              buttonLink1={`/${setLocaleInUrl}${slugify(
                sanityAllVacanciesPage._rawLocaleTitle[locale]
              )}`}
              youtubeUrl={youtubeBackgroundVideo}
              xl
            />
          </HeroFullWidth>
        )
      )}
      {_rawTextBlock1 && (
        <Section paddingTop paddingBottom>
          <Container>
            <Grid container justify="center">
              <Grid item xs={12} md={8}>
                <IntroBlockStyle>
                  <BlockText blocks={_rawTextBlock1[locale]} />
                </IntroBlockStyle>
              </Grid>
            </Grid>
          </Container>
        </Section>
      )}
      <SvgBorderTop grey>
        <Container grey paddingTop paddingBottom>
          <AfricaOutlineBackground>
            {_rawDestinationsTitle && (
              <Section paddingBottom>
                <Grid container>
                  <Grid item xs={false} md={1} />
                  <Grid item xs={12} md={6}>
                    <BlockTitle>{_rawDestinationsTitle[locale]}</BlockTitle>
                    {_rawDestinationstextBlock && (
                      <Vspacer marginBottom={vSpacing.baseX5}>
                        <BlockText blocks={_rawDestinationstextBlock[locale]} />
                      </Vspacer>
                    )}
                    {allSanityVacancyCountry && (
                      <>
                        <Grid container item xs={12} md={12}>
                          {allSanityVacancyCountry.edges.map((country) => (
                            <Grid item xs={12} md={6} key={country.node.id}>
                              <Vspacer marginBottom={vSpacing.baseX2}>
                                <LinkUnderline
                                  to={`/${setLocaleInUrl}${slugify(
                                    country.node._rawLocaleTitle[locale]
                                  )}`}
                                >
                                  <Location>
                                    {country.node.flag && (
                                      <img
                                        src={`${country.node.flag.asset.url}?w=24`}
                                        style={{
                                          marginRight: "12px",
                                          maxWidth: "24px",
                                        }}
                                        alt={
                                          country.node._rawLocaleTitle[
                                            locale
                                          ] || "Country"
                                        }
                                      />
                                    )}

                                    <p>
                                      {country.node._rawLocaleTitle[locale]}
                                    </p>
                                  </Location>
                                </LinkUnderline>
                              </Vspacer>
                            </Grid>
                          ))}
                        </Grid>
                        {sanityAboutUsPage && (
                          <Grid container>
                            <Vspacer marginTop={vSpacing.baseX5}>
                              <LinkUnderline
                                to={`/${setLocaleInUrl}${slugify(
                                  sanityAboutUsPage._rawLocaleTitle[locale]
                                )}`}
                              >
                                {localeData._rawReadMoreAboutUs[locale]}
                              </LinkUnderline>
                            </Vspacer>
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Section>
            )}
            {_rawProjectTypesTitle && (
              <Section>
                <Grid container>
                  <Grid item xs={false} md={5} />
                  <Grid item xs={12} md={6}>
                    <BlockTitle>{_rawProjectTypesTitle[locale]}</BlockTitle>
                    {_rawProjectTypestextBlock && (
                      <Vspacer marginBottom={vSpacing.baseX5}>
                        <BlockText blocks={_rawProjectTypestextBlock[locale]} />
                      </Vspacer>
                    )}
                    {allSanityVacancyCategory && (
                      <>
                        <Grid container item xs={12} md={12}>
                          {allSanityVacancyCategory.edges.map((category) => (
                            <Grid item xs={12} md={4} key={category.node.id}>
                              <Link
                                to={`/${setLocaleInUrl}${slugify(
                                  sanityAllVacanciesPage._rawLocaleTitle[locale]
                                )}?category=${
                                  category.node._rawLocaleTitle[locale]
                                }`}
                              >
                                <Location>
                                  {category.node.categoryIcon && (
                                    <img
                                      src={`${category.node.categoryIcon.asset.url}?w=24`}
                                      style={{
                                        marginRight: "12px",
                                        maxWidth: "24px",
                                      }}
                                      alt={
                                        category.node._rawLocaleTitle[locale] ||
                                        "Category"
                                      }
                                    />
                                  )}
                                  <p>{category.node._rawLocaleTitle[locale]}</p>
                                </Location>
                              </Link>
                            </Grid>
                          ))}
                        </Grid>
                        {sanityAllVacanciesPage && (
                          <Grid container>
                            <Vspacer marginTop={vSpacing.baseX5}>
                              <LinkUnderline
                                to={`/${setLocaleInUrl}${slugify(
                                  sanityAllVacanciesPage._rawLocaleTitle[locale]
                                )}`}
                              >
                                {localeData._rawExploreVacancyBtn[locale]}
                              </LinkUnderline>
                            </Vspacer>
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Section>
            )}
          </AfricaOutlineBackground>
        </Container>
      </SvgBorderTop>

      {image2 && (
        <Container grey paddingBottom>
          <HeroFullWidthMiddle img={image2} />
        </Container>
      )}
      <Container grey paddingBottom>
        <Vspacer marginBottom={vSpacing.baseX6}>
          <Grid container justify="center">
            <Grid item md={6}>
              <Title4 center>{localeData._rawPopularVacancies[locale]}</Title4>
            </Grid>
          </Grid>
        </Vspacer>
        <Section>
          <VacancySelection locale={locale} />
        </Section>
      </Container>

      <Container grey paddingBottom>
        <StorySelection locale={locale} />
      </Container>

      <SvgBorderTop>
        <Vspacer paddingTop="80px" paddingBottom="160px">
          <Container>
            <Section>
              <Grid container>
                {allSanityVacancy && (
                  <Grid item xs={12} md={4}>
                    <Stats>{allSanityVacancy.edges.length}</Stats>
                    <StatsTitle>
                      {localeData._rawDifferentVacanciesTitle[locale]}
                    </StatsTitle>
                  </Grid>
                )}
                {sanitySiteSettings.amountOfPartners && (
                  <Grid item xs={12} md={4}>
                    <Stats>{sanitySiteSettings.amountOfPartners}</Stats>
                    <StatsTitle>
                      {localeData._rawPartnersTitle[locale]}
                    </StatsTitle>
                  </Grid>
                )}
                {sanitySiteSettings.clientAmount && (
                  <Grid item xs={12} md={4}>
                    <Stats>{sanitySiteSettings.clientAmount}</Stats>
                    <StatsTitle>
                      {localeData._rawPeopleTitle[locale]}
                    </StatsTitle>
                  </Grid>
                )}
              </Grid>
            </Section>
          </Container>
        </Vspacer>
      </SvgBorderTop>
      {teamMember && (
        <SvgBorderTop grey>
          <Container grey paddingBottom>
            <Section>
              <LetsConnect data={teamMember} locale={locale} />
            </Section>
          </Container>
        </SvgBorderTop>
      )}
    </>
  )
}

export default IndexPage
